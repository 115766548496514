import React from "react";
import './App.css';
import axios from "axios";
import Nav from "../nav/Nav";
// noinspection ES6CheckImport
import {
    BrowserRouter,
    Routes,
    Route,
    Navigate
} from "react-router-dom"
import Apps from "../apps/Apps";
import Loading from "../loading/Loading";
import Login from "../login/Login";
import AddApp from "../apps/AddApp";
import UpdateStatus from "../apps/UpdateStatus";
import DeleteApp from "../apps/DeleteApp";
import SingleInfo from "../single-info/SingleInfo";

class App extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            apps: null,
            token: localStorage.getItem('ArtMikScanToken') ? localStorage.getItem('ArtMikScanToken') : ''
        };

        this.loadApps = this.loadApps.bind(this);
        this.handleLogin = this.handleLogin.bind(this);
        this.handleLogout = this.handleLogout.bind(this);
    }

    componentDidMount() {
        this.loadApps();
    }

    loadApps() {
        if (!this.state.token) {
            return;
        }
        const url = "https://test-scan.artmik.ru/api/apps";
        const config = {
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + this.state.token
            }
        };

        axios.get(url, config)
            .then(response => {
                console.log(response);
                this.setState({
                    apps: response.data.message
                })
            })
            .catch(error => {
                console.log(error);
                alert(error.message);
            });
    }

    handleLogin(token) {
        this.setState({
            token: token
        }, () => {
            localStorage.setItem('ArtMikScanToken', token);
            window.location.href = window.location.href.replace("/login", "");
        });
    }

    handleLogout() {
        this.setState({
            token: ''
        }, () => {
            localStorage.setItem('ArtMikScanToken', '');
        });
    }

    render() {
        return (
            <div className="app-block">
                <BrowserRouter key={this.state.token}>
                    {this.state.token !== '' ?
                        <Nav onLogout={this.handleLogout}/> :
                        null
                    }
                    <div className="main-content">
                        <Routes>
                            <Route path="/" element={<Navigate to={"/apps"}/>}/>
                            <Route path="/apps/add" element={
                                this.state.token !== '' ?
                                    <AddApp token={this.state.token}/> :
                                    <Navigate to="/login"/>
                            }
                            />
                            <Route path="/apps/update-status" element={
                                this.state.token !== '' ?
                                    this.state.apps ?
                                        <UpdateStatus token={this.state.token} apps={this.state.apps}/> :
                                        <Loading/> :
                                    <Navigate to="/login"/>
                            }/>
                            <Route path="/apps/delete" element={
                                this.state.token !== '' ?
                                    this.state.apps ?
                                        <DeleteApp token={this.state.token} apps={this.state.apps}/> :
                                        <Loading/> :
                                    <Navigate to="/login"/>
                            }/>
                            <Route path="apps/:id" element={
                                this.state.token !== '' ?
                                    this.state.apps ?
                                        <SingleInfo token={this.state.token} apps={this.state.apps}/> :
                                        <Loading/> :
                                    <Navigate to="/login"/>
                            }/>
                            <Route path="/apps" element={
                                this.state.token !== '' ?
                                    this.state.apps ?
                                        <Apps apps={this.state.apps}/> :
                                        <Loading/> :
                                    <Navigate to="/login"/>
                            }/>
                            <Route path="/login" element={<Login onLogin={this.handleLogin}/>}/>
                        </Routes>
                    </div>
                </BrowserRouter>
            </div>
        )
    }

}

export default App;
