import React from "react";
import 'bootstrap/dist/css/bootstrap.css';
import axios from "axios";

class AddApp extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            token: this.props.token,
            bundle: ''
        }

        this.handleBundleChange = this.handleBundleChange.bind(this);
        this.handleAddApp = this.handleAddApp.bind(this);
    }

    handleBundleChange(event) {
        this.setState({
            bundle: event.target.value
        });
    }

    handleAddApp() {
        if (!this.state.token) {
            return;
        }
        const url = "https://test-scan.artmik.ru/api/apps";
        const config = {
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + this.state.token
            }
        };
        const data = {
            bundle: this.state.bundle
        };

        axios.post(url, data, config)
            .then(response => {
                console.log(response);
                if (response.data.status === 'success') {
                    document.getElementById("addAppStatus").style.color = "#00FF00";
                    document.getElementById("addAppStatus").innerText = "Success!";
                } else {
                    document.getElementById("addAppStatus").style.color = "#FF0000";
                    document.getElementById("addAppStatus").innerText = "Error! " + response.data.message;
                }
            })
            .catch(error => {
                console.log(error);
                document.getElementById("addAppStatus").style.color = "#FF0000";
                document.getElementById("addAppStatus").innerText = "Error! " + error.message;
            });

        this.setState({
            bundle: ""
        });
    }

    render() {
        return (
            <div>
                <h2>Add application:</h2>
                <br/>
                <input className={'form-control'} type='text' value={this.state.bundle} placeholder={"bundle"} onChange={this.handleBundleChange}/>
                <br/>
                <button className={'btn btn-dark'} disabled={this.state.bundle === ''} onClick={this.handleAddApp}>Add</button>
                <br/>
                <br/>
                <p id={"addAppStatus"}/>
            </div>
        )
    }
}

export default AddApp;