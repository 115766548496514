import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import './Nav.css';
import {Link} from "react-router-dom";

const tabs = [
    { title: 'Apps', link: '/apps' }
];

class Nav extends React.Component {
    render() {
        return (
            <div>
                <nav className="navbar navbar-expand-sm navbar-dark bg-dark nav-panel">
                    <Link className="navbar-brand" to="/">Scan</Link>
                    <button className="navbar-toggler" type="button" data-toggle="collapse"
                            data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                            aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"/>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav mr-auto">
                            {tabs.map(tab =>
                                <li key={tab.title + tab.link} className="nav-item active">
                                    <Link className="nav-link" to={tab.link}>{tab.title}</Link>
                                </li>
                            )}
                        </ul>
                        <button className="btn btn-outline-danger align-right" onClick={this.props.onLogout}>Logout</button>
                    </div>
                </nav>
            </div>
        )
    }
}

export default Nav;