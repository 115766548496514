import React from "react";
import 'bootstrap/dist/css/bootstrap.css';
import axios from "axios";
import Loading from "../loading/Loading";

class SingleInfo extends React.Component {
    constructor(props) {
        super(props);

        if (!Array.prototype.last) {
            // eslint-disable-next-line no-extend-native
            Array.prototype.last = function () {
                return this[this.length - 1];
            };
        }

        this.state = {
            token: this.props.token,
            id: Number(window.location.href.split('/').last()),
            apps: this.props.apps,
            localCode: "EN-US",
            info: null
        }

        this.loadInfo = this.loadInfo.bind(this);
        this.handleLocalCodeChanged = this.handleLocalCodeChanged.bind(this);
    }

    componentDidMount() {
        this.loadInfo();
    }

    loadInfo() {
        if (!this.state.token) {
            return;
        }
        const url = "https://test-scan.artmik.ru/api/apps/info";

        axios.get(url, {
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + this.state.token
            },
            params: {
                local_code: this.state.localCode,
                bundle: (this.state.apps.find(app => app.id === this.state.id)).bundle
            }
        })
            .then(response => {
                console.log(response);
                this.setState({
                    info: response.data.message
                })
            })
            .catch(error => {
                console.log(error);
                alert(error.message);
            });
    }

    handleLocalCodeChanged(event) {
        this.setState({
            info: null,
            localCode: event.target.value
        }, () => {
            this.loadInfo();
        });
    }

    render() {
        return (
            <div>
                {this.state.info ?
                    <div className="container">
                        <div className="row">
                            <div className="col-sm">
                                Bundle:
                                <input className='form-control' type="text" disabled={true} value={(this.state.apps.find(app => app.id === this.state.id)).bundle}/>
                            </div>
                            <div className="col-sm">
                                Local code:
                                <select className='form-select' value={this.state.localCode} onChange={this.handleLocalCodeChanged}>
                                    <option value="EN-US">EN-US</option>
                                    <option value="RU-RU">RU-RU</option>
                                    <option value="PT-PT">PT-PT</option>
                                    <option value="ES-ES">ES-ES</option>
                                    <option value="DE-DE">DE-DE</option>
                                </select>
                            </div>
                        </div>
                        <br/>
                        <br/>
                        {(this.state.info.locale_content && this.state.info.main_content ?
                            <div>
                                <div className="row justify-content-md-start">
                                    <div className="col-sm-auto">
                                        <img src={this.state.info.locale_content.icon_url} alt="icon"/>
                                    </div>
                                    <div className="col-sm">
                                        <h3>{this.state.info.locale_content.title}</h3>
                                        <br/>
                                        Rating: <b>{this.state.info.locale_content.rating}</b>
                                        <br/>
                                        Prices: <b>{this.state.info.locale_content.price_from + " - " + this.state.info.locale_content.price_to}</b>
                                        <br/>
                                        Downloads: <b>{this.state.info.main_content.downloads}+</b>
                                        <br/>
                                        Version: <b>{this.state.info.main_content.version}</b>
                                        <br/>
                                        Last update: <b>{this.state.info.main_content.edited_at}</b>
                                    </div>
                                </div>
                                <br/>
                                <div className="row">
                                    <div className="col-lg">
                                        Description:
                                        <br/>
                                        <br/>
                                        {this.state.info.locale_content.description}
                                    </div>
                                </div>
                            </div> :
                            <div>
                                <h3>No information.</h3>
                            </div>
                        )}
                    </div> :
                    <Loading/>
                }
            </div>
        )
    }
}

export default SingleInfo;