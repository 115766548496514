import React from "react";
import 'bootstrap/dist/css/bootstrap.css';
import './Apps.css';
import axios from "axios";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

class DeleteApp extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            token: this.props.token,
            apps: this.props.apps,
            currentBundle: this.props.apps[0].bundle
        }

        this.handleCurrentBundleChange = this.handleCurrentBundleChange.bind(this);
        this.handleDeleteApp = this.handleDeleteApp.bind(this);
        this.deleteApp = this.deleteApp.bind(this);
    }

    handleCurrentBundleChange(event) {
        this.setState({
            currentBundle: event.target.value
        });
    }

    handleDeleteApp() {
        if (!this.state.token) {
            return;
        }

        confirmAlert({
            title: 'Delete app',
            message: 'Are you sure you wanna delete ' + this.state.currentBundle + ' ?',
            buttons: [
                {
                    label: 'Yes, delete it.',
                    onClick: () => this.deleteApp(this.state.currentBundle)
                },
                {
                    label: 'Cancel'
                }
            ]
        });
    }

    deleteApp(bundle) {
        const url = "https://test-scan.artmik.ru/api/apps";

        axios.delete(url, {
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + this.state.token
            },
            data: {
                bundle: bundle
            }})
            .then(response => {
                console.log(response);
                if (response.data.status === 'success') {
                    document.getElementById("deleteAppStatus").style.color = "#00FF00";
                    document.getElementById("deleteAppStatus").innerText = "Success!";
                } else {
                    document.getElementById("deleteAppStatus").style.color = "#FF0000";
                    document.getElementById("deleteAppStatus").innerText = "Error! " + response.data.message;
                }
            })
            .catch(error => {
                console.log(error);
                document.getElementById("deleteAppStatus").style.color = "#FF0000";
                document.getElementById("deleteAppStatus").innerText = "Error! " + error.message;
            });
    }

    render() {
        return (
            <div>
                App:
                <select className={'form-select width-half'} value={this.state.currentBundle} onChange={this.handleCurrentBundleChange}>
                    {this.state.apps.map(app =>
                        <option value={app.bundle}>{app.bundle}</option>
                    )}
                </select>
                <br/>
                <br/>
                <button className={'btn btn-danger'} onClick={this.handleDeleteApp}>Delete</button>
                <br/>
                <p id={"deleteAppStatus"}/>
            </div>
        )
    }
}

export default DeleteApp;