import React from "react";
import 'bootstrap/dist/css/bootstrap.css';
import './Apps.css';
import axios from "axios";

const statuses = ["DRAFT", "MODERATION", "PUBLISHED", "BANNED", "DECLINED", "ARCHIVE"];

class UpdateStatus extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            token: this.props.token,
            apps: this.props.apps,
            currentBundle: this.props.apps[0].bundle,
            currentStatus: statuses[0]
        }

        this.handleCurrentBundleChange = this.handleCurrentBundleChange.bind(this);
        this.handleCurrentStatusChange = this.handleCurrentStatusChange.bind(this);
        this.handleUpdateStatus = this.handleUpdateStatus.bind(this);
    }

    handleCurrentBundleChange(event) {
        this.setState({
            currentBundle: event.target.value
        });
    }

    handleCurrentStatusChange(event) {
        this.setState({
            currentStatus: event.target.value
        });
    }

    handleUpdateStatus() {
        if (!this.state.token) {
            return;
        }
        const url = "https://test-scan.artmik.ru/api/apps/status";
        const config = {
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + this.state.token
            }
        };
        const data = {
            bundle: this.state.currentBundle,
            status: this.state.currentStatus
        };

        axios.put(url, data, config)
            .then(response => {
                console.log(response);
                if (response.data.status === 'success') {
                    document.getElementById("updateStatusStatus").style.color = "#00FF00";
                    document.getElementById("updateStatusStatus").innerText = "Success!";
                } else {
                    document.getElementById("updateStatusStatus").style.color = "#FF0000";
                    document.getElementById("updateStatusStatus").innerText = "Error! " + response.data.message;
                }
            })
            .catch(error => {
                console.log(error);
                document.getElementById("updateStatusStatus").style.color = "#FF0000";
                document.getElementById("updateStatusStatus").innerText = "Error! " + error.message;
            });
    }

    render() {
        return (
            <div className='container'>
                <div className='row'>
                    <div className='col-sm'>
                        App:
                        <select className={'form-select'} value={this.state.currentBundle} onChange={this.handleCurrentBundleChange}>
                            {this.state.apps.map(app =>
                                <option value={app.bundle}>{app.bundle}</option>
                            )}
                        </select>
                    </div>
                    <div className='col-sm'>
                        New status:
                        <select className={'form-select'} value={this.state.currentStatus} onChange={this.handleCurrentStatusChange}>
                            {statuses.map(status =>
                                <option value={status}>{status}</option>
                            )}
                        </select>
                    </div>
                </div>
                <br/>
                <div className='row'>
                    <div className='col-sm'>
                        <button className={'btn btn-dark'} onClick={this.handleUpdateStatus}>Update</button>
                    </div>
                </div>
                <br/>
                <br/>
                <p id={"updateStatusStatus"}/>
            </div>
        );
    }
}

export default UpdateStatus;