import React from "react";
import DataTable from 'react-data-table-component';
import 'bootstrap/dist/css/bootstrap.css';
import {Link} from "react-router-dom";

class Apps extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            apps: props.apps
        };

        this.handleRowClicked = this.handleRowClicked.bind(this);
    }

    handleRowClicked(row) {
        window.location.href += ("/" + row.id);
    }

    render() {
        // noinspection JSUnresolvedVariable
        const columns = [
            {
                name: "ID",
                selector: app => Number(app.id),
                sortable: true
            },
            {
                name: "Bundle",
                selector: app => app.bundle,
                sortable: true
            },
            {
                name: "Host code",
                selector: app => Number(app.host_code),
                sortable: true
            },
            {
                name: "Scannable",
                selector: app => Number(app.scannable),
                sortable: true
            },
        ];

        const styles = {
            cells: {
                style: {
                    cursor: 'pointer'
                }
            }
        }

        return (
            <div>
                <Link to={"/apps/add"}><button className={'btn btn-outline-dark'}>Add application</button></Link>
                &emsp;
                <Link to={"/apps/update-status"}><button className={'btn btn-outline-dark'}>Update app status</button></Link>
                &emsp;
                <Link to={"/apps/delete"}><button className={'btn btn-outline-dark'}>Delete app</button></Link>
                <br/>
                <br/>
                <DataTable
                    columns={columns}
                    customStyles={styles}
                    data={this.state.apps}
                    pagination={true}
                    theme={"dark"}
                    onRowClicked={this.handleRowClicked}
                />
            </div>
        );
    }
}

export default Apps;